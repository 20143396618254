$font-base: 'Lato', sans-serif;
$font-title: 'Charmonman', cursive;
$font-decorative: 'Ribeye Marrow', cursive;

$color-base: #FFF;
$color-shadow: #272727;
$color-shadow-dark: #000;
$color-box: #8b8b8b;

$color-skills: #786FB8;
$color-home: #572EB9;
$color-linkedin: #4A53D8;
$color-facebook: #6E93E8;
$color-github: #86C3ED;
$color-email: #A7F1E1;
$color-instagram: #C4FFDA;
$color-transparent: transparent;