.projects {

  h2 {
  }

  &__images {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 50%;
    height: 60%;
    z-index: 11;
    overflow: hidden;
    position: relative;
    margin: 1rem 0;

    @include phone {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      width: 60%;
    }

    &--show {
      @include animation(slide-from-top 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both);
    }

    &--hide {
      @include animation(slide-to-top 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both);
    }

    &__single {
      display: flex;
      margin: 0.5rem;
      align-items: center;
      width: 30%;
      height: 10rem;
      cursor: pointer;
      @include transition(2s);
      filter: grayscale(100%);
      border: 0.5rem double $color-base;
      position: relative;
      bottom: 10rem;

      @include phone {
        width: auto;
      }

      &:first-of-type, &:nth-of-type(2), &:nth-of-type(3) {
        animation-delay: 0s;
      }

      &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6) {
        animation-delay: .1s;
      }

      &:nth-of-type(7), &:nth-of-type(8), &:nth-of-type(9) {
        animation-delay: .2s;
      }

      &:hover {
        filter: grayscale(0%);
      }
    }

    &__details {
      display: flex;
      width: 100%;
      position: relative;
      bottom: 10rem;

      @include phone {
        flex-direction: column;
      }

      &__photos {
        display: flex;
        justify-content: space-between;
        align-content: center;
        width: 50%;

        @include phone {
          width: 100%;
        }

        img:only-child {
          margin: 0 auto;
        }

        img {
          &:first-of-type {
            z-index: 1;
            height: 20rem;
          }

          &:nth-of-type(2) {
            position: absolute;
            left: 2rem;
            bottom: 2rem;
            height: 20rem;
            z-index: 0;
            top: 0;

            @include phone {
              bottom: auto;
              height: 60%;
            }
          }
        }

      }

      &__text {
        display: flex;
        flex-direction: column;
        padding: 1rem;

        &:after {
          content: "";
          bottom: 0;
          width: 50%;
          height: 100%;
          background: $color-base;
          position: absolute;
          right: 0;
          z-index: -1;
          @include opacity(.3);

          @include phone {
            width: 100%;
          }
        }

        img {
          vertical-align: middle;
          width: 1.5rem;
          margin: 0 0.5rem;

        }

        p {
          margin: 1rem;
          font-family: $font-base;
        }

        i {
          margin: 0 1rem;
        }
      }
    }

    &__description {
      margin-top: 2rem;
      width: 100%;
      height: auto;
      position: relative;
      padding: 1rem;
      bottom: 10rem;

      @include phone {
        height: 30%;
        padding: 0.5rem;
      }

      &:after {
        content: "";
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: $color-base;
        position: absolute;
        z-index: -1;
        @include opacity(.3);
      }

      p {
        margin: 1rem;
        height: 60%;

        @include phone {
          height: auto;
          margin: 1rem;
        }
      }

      &__links {
        height: 10%;
        display: flex;
        justify-content: flex-end;

        @include phone {
          position: absolute;
          bottom: 1rem;
        }

        a {
          color: $color-shadow;
        }

        button {
          @include opacity(.5);
          width: 4rem;
          height: 1.5rem;
          background: $color-base;
          margin: 0 1rem;
          cursor: pointer;
          @include transition(1s);
          @include font-size(.8);

          &:hover {
            @include opacity(1);
          }
        }
      }
    }
  }

  &__buttons {
    display: flex;
    border-radius: 1rem;
    z-index: 10;

    button {
      margin: -0.2rem 1rem;
      padding: 0.5rem 1rem;
      font-size: 0.8rem;
      cursor: pointer;
      border-radius: 50%;
    }

    &--active {
      @include transition(2s);
      background: $color-home;
      @include font-size(1);
      color: $color-base;
      font-weight: 600;
    }

    .back {
      @include font-size(.8);

      &:hover {
        @include opacity(.5);
      }
    }

    &__big, &__small {
      background: $color-box;
      border-radius: 50%;
      margin: 0 1rem;
    }

    &__big {
      width: 2rem;
      height: 2rem;
    }

    &__small {
      width: 1rem;
      height: 1rem;
      margin: 0.5rem;
    }

    i {
      @include font-size(2);
      color: $color-home;
      cursor: pointer;
      @include transition(1s);

      &:hover {
        @include opacity(.5);
      }
    }
  }
}

.show--page {
  @include animation(fadeIn .7s ease);
}

.hide--page {
  @include animation(fadeOut .7s ease);
}

@include keyframes (slide-from-top) {
  0% {
    @include translate(0, -60rem);
  }
  100% {
    @include translate(0, 10rem);
  }
}

@include keyframes (slide-to-top) {
  0% {
    @include translate(0, 10rem);
  }
  100% {
    @include translate(0, -60rem);
  }
}