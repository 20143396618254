.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  overflow: hidden;

  &__background {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 57%;
    height: 86%;
    z-index: 10;

    @include phone {
      width: 75%;
    }
  }

  &__title {
    z-index: 10;
    line-height: 4rem;
    margin-bottom: 2rem;

    h1 {
      font-family: $font-decorative;
    }

    p {
      z-index: 10;
      color: $color-base;
      font-family: $font-title;
      @include font-size(2);
      text-shadow: 2px 2px 0 $color-shadow;
      animation: type 4s steps(60, end);
      white-space: nowrap;
      overflow: hidden;
      padding-bottom: 0.5rem;
    }

    i {
      color: $color-base;
    }
  }

  &__links {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    height: 100%;
    width: 100%;

    &__list {
      display: flex;
      justify-content: space-between;
      z-index: 10;
      width: 100%;
      height: 100%;

      &__single {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-content: flex-end;
        height: 100%;
        width: 30.3%;
        position: relative;
        cursor: pointer;
        @include transition(2s);

        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: cover;
          background: url("./../../assets/pattern.png") no-repeat;
          @include opacity(.3);
          filter: grayscale(100%);
          border: 0.1rem double $color-base;
        }

        &:first-of-type {
          &:before {
            @include scaleX(-1);
          }
        }

        &:nth-of-type(2) {
          &:before {
            background: url("./../../assets/pattern2.png") no-repeat center;
            background-size: cover;
          }
          &.hidden {
            &:before {
              background: $color-base url("./../../assets/pattern2.png") no-repeat center;
              background-size: cover;
            }
          }
        }

        span {
          z-index: 20;
          padding: 0.5rem;
          color: $color-base;
          text-shadow: 2px 2px 2px $color-shadow;
        }
      }
    }
  }
}

.hidden {
  @include opacity(1);

  &:before {
    z-index: 20;
    background: $color-base url("./../../assets/pattern.png") no-repeat;
    background-size: cover;
    @include transition(2s);
    @include opacity(.4);
    filter: grayscale(100%);

    @include phone {
      background-position: bottom;
    }
  }

  span {
    @include font-size(.8);
    @include rotate(0);
    @include translate(0, 0);
    @include transition(2s ease-in-out);
  }
}

.hover {
  @include opacity(.8);

  &:before {
    z-index: 20;
    background: url("./../../assets/pattern.png") no-repeat left bottom;
    background-size: cover;
    @include transition(2s);
    @include opacity(1);
    filter: grayscale(50%);

    @include phone {
      background-position: bottom;
    }
  }

  span {
    @include font-size(2);
    @include transform(rotate(90deg) translate(0, -8rem));
    @include transition(2s ease-in-out);

    @include phone {
      @include font-size(1.5);
      @include transform(rotate(90deg) translate(-4rem, -2rem));
    }
  }
}

.transition1, .transition2, .transition3 {
  position: absolute;
  @include opacity(0);
  background: $color-base;
  height: 64.5%;
  width: 30%;
  @include transition(3s);
  bottom: 0;
}

.transition1 {
  left: 0;
}

.transition2 {
  left: 35%;
}

.transition3 {
  left: 70%;
}

.show {
  position: absolute;
  width: 100%;
  height: 100%;
  @include opacity(.5);
  z-index: 10;
  left: 0;
  bottom: 0;
}

@include keyframes (type) {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}