@import "./../utils/variables.scss";
@import "./../utils/mixins.scss";

html {
  width: 100vw;
  height: 100vh;
  background-color: $color-base;
  position: relative;
  overflow: hidden;
  @include phone {
    font-size: 60%;
  }
}

body {
  line-height: 1.5;
  font-size: 10px;

  @include phone {
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
  }
}

article, aside, figcaption, figure, footer, header, nav, section {
  display: block;

}
h1, h2, h3, h4 {
  text-shadow: 2px 2px 0 $color-shadow, 4px 4px 0 $color-shadow-dark;

  @include phone {
    text-shadow: 1px 1px 0 $color-shadow, 2px 2px 0 $color-shadow-dark;
  }
}

h1, h2, h3, h4, h5 {
  line-height: 1.25;
  z-index: 10;
  color: $color-base;
  text-align: center;
  margin: 1rem 0;
}

h1 {
  @include font-size(5);
}

h2 {
  @include font-size(3);
  font-family: $font-title;
}

h3 {
  @include font-size(2);
}

h4 {
  @include font-size(1);
}

h5 {
  @include font-size(.8);
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  font-family: $font-base;
  @include font-size(1.1);
  margin: 1rem 0;
  color: $color-shadow;

  .decorative {
    margin: 0 0.3rem;
    &:nth-of-type(even) {
      color: $color-linkedin;
    }

    &:nth-of-type(odd) {
      color: $color-facebook;
    }
  }
}

button {
  background: transparent;
  border: none;
}

section {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: url("./../../assets/background.png") no-repeat;
  background-size: cover;
}

.background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;

  &:before {
    content: "";
    position: absolute;
    width: 57%;
    height: 86%;
    z-index: 1;
    @include opacity(.5);
    background: $color-base;

    @include phone {
      width: 75%;
    }
  }
}

.go--back {
  @include opacity(.8);
  position: absolute;
  z-index: 20;
  left: 0;
  @include translate(0, -30%);
  width: 4rem;
  height: 6rem;
  border-left: solid 6rem $color-skills;
  border-bottom: solid 6rem $color-transparent;
  border-top: solid 6rem $color-transparent;
  cursor: pointer;
  @include transition(3s);

  @include phone {
    border-left: solid 4rem $color-skills;
    border-bottom: solid 4rem $color-transparent;
    border-top: solid 4rem $color-transparent;
  }

  &:hover {
    border-left: solid 6rem $color-base;

    span {
      border-right: solid 2rem $color-shadow-dark;
      left: -5rem;
    }
  }

  span {
    position: absolute;
    bottom: -2rem;
    width: 2rem;
    height: 3rem;
    border-right: solid 2rem $color-skills;
    border-bottom: solid 2rem $color-transparent;
    border-top: solid 2rem $color-transparent;
    @include transition(2s);
    left: 0;
  }
}

.back--to--home1, .back--to--home2, .back--to--home3 {
  &:before {
    @include animation(backToHome 3s forwards);
  }
}

.back--to--home1 {
  &:before {
    @include transform-origin(bottom left);
    bottom: 7%;
    left: 21.5%;

    @include phone {
      left: 12.5%;
    }
  }
}

.back--to--home2 {
  &:before {
    @include transform-origin(bottom);
    bottom: 7%;
  }
}

.back--to--home3 {
  &:before {
    @include transform-origin(bottom right);
    bottom: 7%;
    right: 21.5%;

    @include phone {
      right: 12.5%;
    }
  }
}

@include keyframes(backToHome) {
  to {
    opacity: 0;
    width: 18%;
    height: 51%;
  }
}

.fade--in {
  @include animation(fadeIn 3s ease);
}

.fade--out {
  @include animation(fadeOut 3s ease);
}

@include keyframes(fadeIn) {
  0% {
    @include opacity(0);
  }
  100% {
    @include opacity(1);
  }
}


@include keyframes(fadeOut) {
  0% {
    @include opacity(1);
  }
  100% {
    @include opacity(0);
  }
}

.tooltip {
  position: relative;
  display: inline-block;

  .tooltiptext {
    width: 90%;
    height: 1rem;
    bottom: -1rem;
    right: 0;
    background-color: $color-base;
    text-align: center;
    padding: 0.1rem 0;
    position: absolute;
    z-index: 15;
    font-family: $font-base;
    @include font-size(.6);
    @include transition(opacity 1s ease-in-out);
  }
}

::-webkit-scrollbar {
  width: 0.5rem;
  margin: 0 1rem;

  &-track {
    background: $color-base;
  }

  &-thumb {
    background: $color-box;
  }
}