.skills {

  &__section {
    z-index: 11;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    height: 80%;
    width: 50%;

    @include phone {
      width: 70%;
    }

    h2 {
      height: 10%;
      margin-bottom: 3rem;
    }

    &__images {
      display: flex;
      justify-content: center;
      align-content: center;
      height: 80%;
      width: 100%;

      &__tools {
        margin: 0 1rem;
        width: 100%;
        display: block;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 1rem;

        h5 {
          position: relative;
          font-family: $font-base;
          text-shadow: 2px 2px 2px $color-shadow;
          z-index: 15;
          padding-left: 0.5rem;

          &:after, &:before {
            content: "";
            position: absolute;
            box-shadow: 10px 10px 10px $color-box;
            @include opacity(.5);
            width: 100%;
            height: 1.5rem;
          }

          &:after {
            top: -0.3rem;
            left: 0;
            background: $color-skills;
            z-index: -1;
          }

          &:before {
            top: 0.3rem;
            left: 0.3rem;
            background: $color-facebook;
            z-index: -2;
          }
        }

        &__single {
          margin: 2rem 0;

          @include phone {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
          }
        }
      }

      img {
        padding: 1rem;
        vertical-align: middle;
        height: 6rem;
        width: auto;
        filter: grayscale(100%);
        @include transition(2s);
        cursor: pointer;

        &:hover {
          filter: grayscale(0%);
        }
      }
    }
  }
}