.language {
  position: absolute;
  right: 0;
  z-index: 20;
  text-align: right;
  margin: 1rem;

  img {
    width: 4%;
    margin: 0 0.5rem;
    cursor: pointer;
    filter: grayscale(100%);
    @include transition(1s);
  }

  input {
    visibility: hidden;

    &:checked + img  {
      filter: grayscale(0%);
    }
  }
}