.contact {
  position: absolute;
  z-index: 12;

  &__circles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    position: relative;
    cursor: pointer;

    &__main, &__single {
      @include font-size (1.5);
      width: 4rem;
      height: 4rem;
      cursor: pointer;
      margin-left: 3rem;
      margin-top: 3rem;
      border-radius: 50%;
      @include transition(transform 1s ease-out, opacity 1s ease-out, color 1s ease-out);
      position: absolute;
      top: 0;
      left: 0;
      @include opacity(0.8);
      color: transparent;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        @include translate(-50%, -50%);
      }
    }

    .home {
      background-color: $color-home;
      @include translate(40%, 25%);
      z-index: 4;
      @include transition(.3s);
    }

    .linkedin {
      background-color: $color-linkedin;
      @include translate(0, -40%);
      z-index: 3;
      @include transition(.6s);
    }

    .facebook {
      background-color: $color-facebook;
      @include translate(40%, -10%);
      z-index: 2;
      @include transition(.9s);
    }

    .github {
      background-color: $color-github;
      @include translate(-10%, 30%);
      z-index: 1;
      @include transition(1.2s);
    }

    .email {
      background-color: $color-email;
      @include translate(-30%, 0);
      z-index: -1;
      @include transition(1.5s);
    }

    .instagram {
      background-color: $color-instagram;
      @include translate(-40%, -30%);
      z-index: -2;
      @include transition(1.8s);
    }
  }
}

.show--navigation {
  i {
    @include transition(1s);
    color: $color-base;
    text-shadow: 2px 2px 0 #272727;
  }

  .home, .linkedin, .facebook, .github, .email, .instagram {
    &:hover {
      @include transition(1s);
      @include opacity(1);
    }
  }

  .home {
    @include transition(1s);
    @include transform(translate(0, 0) scale(1.2, 1.2));
  }

  .linkedin {
    @include translate(0, 80%);
  }

  .facebook {
    @include translate(0, 160%);
  }

  .github {
    @include translate(0, 240%);
  }

  .email {
    @include translate(0, 320%);
  }

  .instagram {
    @include translate(0, 400%);
  }
}

.hide--navigation {
  i {
    @include transition(1s);
    color: $color-transparent;
  }
}