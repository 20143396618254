.about {

  &__title {
    width: 50%;
    z-index: 11;

    @include phone {
      width: 60%;
    }

    &__image {
      z-index: 11;

      &__shape {
        z-index: 11;
        position: absolute;
        top: 10%;
        right: 20%;
        width: 15%;
        height: 20rem;
        @include skew(20);

        @include phone {
          width: 20%;
          height: 15%;
          top: 5%;
          right: 10%;
        }
      }

      .first {
        background: $color-linkedin;
        filter: drop-shadow(16px 16px 20px $color-linkedin);
      }

      .second {
        @include transform(skew(20deg) rotate(10deg));
        background: $color-facebook;
      }

      .third {
        @include transform(skew(20deg) rotate(20deg));
        transform: skew(20deg) rotate(20deg);
        background: $color-github;
      }

      img {
        position: absolute;

        &:first-of-type {
          top: 30%;
          right: 25%;
          z-index: 20;
          width: 17%;

          @include phone {
            top: 16%;
            right: 18%;
            width: 25%;
          }
        }

        &:last-of-type {
          top: 12%;
          right: 22%;
          width: 12%;
          z-index: 15;

          @include phone {
            width: 17%;
            top: 8%;
            right: 15%;
          }
        }
      }
    }

    &__name {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      h1, h2, p {
        @include animation(typing 5s steps(60, end),);
        white-space: nowrap;
        overflow: hidden;
        text-align: left;
      }

      h1 {
        font-family: $font-title;
        padding: 1rem 0;
      }

      h2 {
        font-family: $font-decorative;

        @include phone {
          @include font-size(2);
        }
      }

      p {
        word-spacing: 0.3rem;
        @include font-size(1.5);
        font-family: $font-base;
        margin: 2rem 0;
        width: 40rem;

        @include phone {
          width: 100%;
          margin: 0.2rem;
        }
      }
    }
  }

  &__description {
    z-index: 11;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 50%;
    margin: 2rem 0;

    @include phone {
      line-height: 1.5rem;
      width: 60%;
      margin: 0.5rem 0;
    }
  }
}

@include keyframes(fadeIn) {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@include keyframes(typing) {
  from { width: 0 }
  to { width: 100% }
}